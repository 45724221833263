/**
 * Expects a baseline "native" width/height object, and a "target" object with either a width or height.
 * Returns the missing dimension, scaled using the same ratio as the one supplied.

 * @example
 * ratioScaler({ width: 50 }, { width: 100, height: 200 })
 * // returns
 * {
 *   width: 50,
 *   height: 100,
 *   ratio: 0.5
 * }
 *
 * @param  {Object} target
 * @param  {number} target.width
 * @param  {number} target.height
 * @param  {Object} native
 * @param  {number} native.width
 * @param  {number} native.height
 * @return {Object} Returns an object containing the supplied target dimension, as well as the missing dimension and the ratio used in the calculation.
 */

const ratioScaler = (target, native) => {
  if (target.width && target.height) return target
  if (!target.width && !target.height) return native

  const ratio = target.width / native.width || target.height / native.height

  return {
    width: native.width * ratio,
    height: native.height * ratio,
    ratio: ratio
  }
}

export default ratioScaler
