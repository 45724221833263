import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Container.module.scss'

export const sizes = ['s', 'm', 'l', 'xl', 'xxl', 'full']

/**
 * A component used to provide a maximum width for child components.
 * Optionally center-aligned, with preset `size` values available. Can
 * also provide gutter spacing.
 */

const Container = ({
  as,
  center,
  children,
  gutter,
  gutterNarrow,
  gutterWide,
  noClearfix,
  size
}) => {
  const ContainerEl = as
  return (
    <ContainerEl
      className={classNames(
        styles.Container,
        center && styles.center,
        gutter && styles.gutter,
        gutterNarrow && styles.gutterNarrow,
        gutterWide && styles.gutterWide,
        noClearfix && styles.noClearfix,
        size && styles[`size-${size}`]
      )}
    >
      {children}
    </ContainerEl>
  )
}

Container.defaultProps = {
  as: 'div',
  size: 'm'
}

Container.propTypes = {
  as: string,
  center: bool,
  children: node.isRequired,
  gutter: bool,
  gutterNarrow: bool,
  gutterWide: bool,
  noClearfix: bool,
  size: oneOf(sizes)
}

export default Container
