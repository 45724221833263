import React from 'react'
import { bool, node, oneOf } from 'prop-types'
import classNames from 'classnames'

import styles from './ButtonStandard.module.scss'

import ButtonBase from '../ButtonBase'
import Spinner from '../Spinner'

const sizes = ['xs', 's', 'm', 'l', 'xl']

/**
 * Basic button component, building on the ButtonBase component.
 */

const ButtonStandard = ({
  alt,
  children,
  disabled,
  inverse,
  loading,
  muted,
  outline,
  shrink,
  size,
  tight,
  visuallyDisabled,
  ...other
}) => (
  <ButtonBase
    className={classNames(
      styles.ButtonStandard,
      alt && styles.alt,
      disabled && styles.disabled,
      visuallyDisabled && styles.visuallyDisabled,
      inverse && styles.inverse,
      loading && styles.loading,
      muted && styles.muted,
      outline && styles.outline,
      shrink && styles.shrink,
      tight && styles.tight,
      (outline || muted) && styles.bordered,
      size && styles[`size-${size}`]
    )}
    disabled={disabled}
    {...other}
  >
    <span className={styles.ButtonStandardInner}>
      <span className={styles.ButtonStandardContent}>{children}</span>
      {loading && (
        <span className={styles.ButtonStandardSpinner}>
          <Spinner revealDelay={200} />
        </span>
      )}
    </span>
  </ButtonBase>
)

ButtonStandard.defaultProps = {
  size: 'm'
}

ButtonStandard.propTypes = {
  alt: bool,
  children: node,
  disabled: bool,
  inverse: bool,
  loading: bool,
  muted: bool,
  outline: bool,
  shrink: bool,
  size: oneOf(sizes),
  tight: bool,
  visuallyDisabled: bool
}

export default ButtonStandard
