import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Type.module.scss'

export const sizes = ['xxl', 'xl', 'l', 'm', 's', 'xs']
export const colors = [
  'blue',
  'lightblue-l50',
  'coolgrey-l50',
  'coolgrey-l25',
  'coolgrey-l17',
  'white'
]

const Type = ({ bold, children, as, color, normal, size, tight }) => {
  const TypeEl = as
  return (
    <TypeEl
      className={classNames(
        styles.Type,
        size && styles[`size-${size}`],
        color && styles[`color-${color}`],
        bold && styles.bold,
        normal && styles.normal,
        tight && styles.tight
      )}
    >
      {children}
    </TypeEl>
  )
}

Type.defaultProps = {
  as: 'div',
  size: 'm'
}

Type.propTypes = {
  as: string,
  bold: bool,
  children: node.isRequired,
  color: oneOf(colors),
  normal: bool,
  size: oneOf(sizes),
  tight: bool
}

export default Type
