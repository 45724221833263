import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './LogoLockup.module.scss'

import Icon from '@/component/Primitive/Icon'
import VisuallyHidden from '@/component/Primitive/VisuallyHidden'

const logos = {
  citygate: {
    icon: 'logo',
    text: 'Citygate',
    height: 32,
    heightSmall: 28
  },
  mycitygate: {
    icon: 'logo-my',
    text: 'My Citygate',
    height: 32,
    heightSmall: 28
  }
}

const LogoLockup = ({
  as,
  href,
  logo,
  title,
  children,
  small,
  mobileStack,
  mobileMinimal,
  tabletMinimal
}) => {
  const LogoLockupEl = as || 'div'

  return (
    <div
      className={classNames(
        styles.LogoLockup,
        small && styles.small,
        mobileStack && styles.mobileStack,
        mobileMinimal && styles.mobileMinimal,
        tabletMinimal && styles.tabletMinimal
      )}
    >
      <LogoLockupEl>
        <a href={href}>
          <Icon
            type={logos[logo].icon}
            height={small ? logos[logo].heightSmall : logos[logo].height}
            a11yText=""
          />
          <VisuallyHidden>{logos[logo].text} </VisuallyHidden>
        </a>
      </LogoLockupEl>
      {title && <div className={styles.LogoLockupStrapline}>{title}</div>}
      {children && <div className={styles.LogoLockupStrapline}>{children}</div>}
    </div>
  )
}

LogoLockup.defaultProps = {
  href: '/',
  logo: 'citygate'
}

LogoLockup.propTypes = {
  as: string,
  href: string,
  logo: oneOf(['citygate', 'mycitygate']),
  title: string,
  children: node,
  small: bool,
  mobileStack: bool,
  mobileMinimal: bool,
  tabletMinimal: bool
}

export default LogoLockup
